import { createRouter, createWebHistory } from 'vue-router'
import { useStorage } from '@vueuse/core'
import { RouteModel, MenuModel } from '@/typings/typings'
import { isEmpty, isNotEmpty } from '@/utils/object-utils'
import { routes } from '@/router/modules/routers'

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 解决刷新路由丢失问题
const serverRouters = useStorage<RouteModel[]>('serverRouters', [])
addServerRouters(serverRouters.value)

export const serverMenus = useStorage<MenuModel[]>('serverMenus', [])
// 重置路由
export function resetRouters() {
  for (const route of routes) {
    // 参数1:路由对象（原始的路由对象）
    router.addRoute(route) // 替换掉同名的路由对象
  }
}
// 动态获取后端路由

export function addServerRouters(routerList: RouteModel[]) {
  if (isNotEmpty(routerList)) {
    const components = import.meta.glob('@/views/**/**/*.vue')
    routerList.forEach((item: any) => {
      try {
        const route = {
          path: item.path,
          name: item.name,
          component: components[`/src/views/${item.component}`],
          meta: { noCache: true, title: item.meta }
        }
        // 参数1:父路由名字 、 参数2:路由对象
        router.addRoute(String(item.parentName), route)
      } catch (e) {
        console.log('addServerRouters===>', e)
      }
    })
  }
  serverRouters.value = routerList
}

router.beforeEach(to => {
  const token = localStorage.getItem('authorize_token')

  if (to.path === '/login') {
    if (checkToken(token)) {
      // token 有效、跳转到首页
      // router.push('/')
      return true
    } else {
      // token 无效
      return true
    }
  } else {
    if (checkToken(token)) {
      // token 有效 直接放行
      return true
    } else {
      // token 无效 跳转到登录页
      router.push('/login')
    }
  }
  return true
})

const checkToken = function (token: any) {
  const menuState = localStorage.getItem('menuState')
  if (isEmpty(token)) {
    return false
  }
  return true
}

export default router
