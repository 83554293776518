import { isEmpty, isNumber, cloneDeep, ceil, add } from 'lodash'
import type { FormInstance } from 'element-plus'
/**
 * 判断不为空
 * @param obj
 * @returns
 */
const isNotEmpty = function (data: any) {
  return !isEmpty(data)
}
/**
 *
 * @param formData 判断FormData对象是否为空
 * @returns is null:true?false
 */
const formDataIsEmpty = (formData: FormData): boolean => {
  for (const pair of formData.entries()) {
    if (pair[1]) {
      return false
    }
  }
  return true
}
/**
 * 删除空属性
 * @param obj 需要处理的对象
 * @returns 删除空属性后的对象
 */
function delObjectNullValue(obj: { [x: string]: any }) {
  const param: { [x: string]: any } = {}
  if (isEmpty(obj)) {
    return param
  }
  for (const key in obj) {
    const val = obj[key]
    if (isNotEmpty(val) || typeof val === 'number') {
      param[key] = obj[key]
    }
  }
  return param
}
/**
 * 表单校验
 * @param formEl 表单对象
 * @returns
 */
async function validateFormData(formEl: FormInstance | undefined) {
  if (!formEl) {
    return false
  }
  const validateFlag = await formEl.validate((valid: any) => {
    return valid
  })
  return validateFlag
}
/**
 * 向前补充固定长度的字符
 * @param sourceNum 原数字
 * @param str 补充的字符
 * @param unit 补充的位数
 * @returns 补充后字符
 */
function fillUpStr(sourceNum: number, str: string, unit: number) {
  return (Array(2).join(str) + sourceNum + '').slice(unit)
}
function getNumberValue(sourceVal: string | undefined) {
  if (!sourceVal) {
    return 0
  }
  try {
    return parseFloat(sourceVal).toFixed(1)
  } catch (e) {
    return 0
  }
}

export {
  isEmpty,
  isNotEmpty,
  formDataIsEmpty,
  isNumber,
  delObjectNullValue,
  validateFormData,
  fillUpStr,
  cloneDeep,
  add,
  ceil,
  getNumberValue
}
