import { RouteRecordRaw } from 'vue-router'
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    redirect: '/plan-task/planV2'
  },
  {
    path: '/home',
    name: 'home',
    // 路由懒加载：又叫延时加载，即在需要的时候进行加载，随用即载。使用路由懒加载后，可以减小app.js文件的大小，从而缩短第一次打开vue项目的加载时间
    component: () => import('@/views/common/home/Home.vue'),
    // 路由异步加载
    // component: resolve => (require(['@/views/Home/home.vue'], resolve))
    children: [
      {
        path: '/command-center/line-single',
        name: 'line-detail',
        component: () => import('@/views/command-center/line-single/line-single.vue')
      },
      {
        path: '/introduction/introduction',
        name: 'introduction',
        meta: { title: '项目展示' },
        component: () => import('@/views/command-center/production-area/introduction/introduction.vue')
      },
      {
        path: '/product-storage/product-storage',
        name: 'product-storage',
        component: () => import('@/views/command-center/production-area/product-storage/product-storage.vue')
      },
      {
        path: '/components/model/line-all',
        name: 'line',
        component: () => import('@/views/command-center/line-all/line-all.vue')
      },
      // {
      //   path: '/flow-two',
      //   name: 'flow-two',
      //   component: () => import('@/views/system-settings/flow/flow-detail/flow-detail.vue'),
      //   meta: { title: '挂篮事业部桥管理' }
      // },
      {
        path: '/wxgl-bridge',
        name: 'wxgl-bridge',
        component: () => import('@/views/wxgl-bridge/wxgl-bridge.vue')
      },
      {
        path: '/command-center/production-area',
        name: 'ProductionArea',
        component: () => import('@/views/command-center/production-area/production-area.vue')
      },
      {
        path: '/command-center/proprietor',
        name: 'Proprietor',
        component: () => import('@/views/command-center/proprietor/proprietor.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/common/login/Login.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/common/login/Login.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/components/isup-video/isup-video.vue')
  },
  {
    path: '/testVideo',
    name: 'test-video',
    component: () => import('@/views/test-video.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/dashboard.vue'),
    // redirect: 'dashboard/production-area',
    children: [
      {
        path: 'command-center/production-area',
        name: 'ProductionArea',
        component: () => import('@/views/command-center/production-area/production-area.vue'),
        meta: { title: '厂区1.0' }
      },
      {
        path: 'production-area',
        name: 'dashboard-production-area',
        component: () => import('@/views/dashboard/production-area/production-area.vue'),
        meta: { title: '厂区' }
      },
      {
        path: 'jikeng',
        name: 'dashboard-jikeng',
        component: () => import('@/views/dashboard/zhu-zhao/jikeng.vue'),
        meta: { title: '基坑' }
      },
      // {
      //   path: 'introduction',
      //   name: 'dashboard-introduction',
      //   component: () => import('@/views/dashboard/project-introduction/project-introduction.vue'),
      //   meta: { title: '项目亮点' }
      // },
      // {
      //   path: 'production',
      //   name: 'dashboard-production',
      //   component: () => import('@/views/dashboard/production/production.vue'),
      //   meta: { title: '生产管理' }
      // },
      // {
      //   path: 'person',
      //   name: 'dashboard-person',
      //   component: () => import('@/views/dashboard/person/person.vue'),
      //   meta: { title: '人员管理' }
      // },
      // {
      //   path: 'material',
      //   name: 'dashboard-material',
      //   component: () => import('@/views/dashboard/material/material.vue'),
      //   meta: { title: '原料存储' }
      // },
      // {
      //   path: 'green-construction',
      //   name: 'dashboard-green-construction',
      //   component: () => import('@/views/dashboard/green-construction/green-construction.vue'),
      //   meta: { title: '绿色施工' }
      // },
      // {
      //   path: 'equipment',
      //   name: 'dashboard-equipment',
      //   component: () => import('@/views/dashboard/equipment/equipment.vue'),
      //   meta: { title: '设备管理' }
      // },
      // {
      //   path: 'safety-monitor',
      //   name: 'dashboard-safety-monitor',
      //   component: () => import('@/views/dashboard/safety-monitor/safety-monitor.vue'),
      //   meta: { title: '巡视监控' }
      // },
      {
        path: 'line-all',
        name: 'dashboard-line-all',
        component: () => import('@/views/dashboard/line-all/line-all.vue'),
        meta: { title: '全部生产线' }
      },
      {
        path: 'line-single',
        name: 'dashboard-line-single',
        component: () => import('@/views/dashboard/line-single/line-single.vue'),
        meta: { title: '单条生产线' }
      },
      {
        path: 'wx',
        name: 'dashboard-wx',
        component: () => import('@/views/dashboard/wx/wx.vue'),
        meta: { title: '五新公司' }
      },
      //====================================五新挂篮造桥机
      {
        path: 'cantilever',
        name: 'DashboardCantilever',
        component: () => import('@/views/dashboard/cantilever/cantilever.vue'),
        meta: { title: '悬浇项目首页' }
      },
      {
        path: 'cantilever-spray',
        name: 'CantileverSpray',
        component: () => import('@/views/dashboard/cantilever-spray/cantilever-spray.vue'),
        meta: { title: '悬浇项目首页-喷淋养护' }
      },
      {
        path: 'cantilever-safety-site',
        name: 'cantilever-safety-site',
        component: () => import('@/views/dashboard/cantilever-safety-site/cantilever-safety-site.vue'),
        meta: { title: '五新挂篮-安防监控' }
      },
      {
        path: 'water-level',
        name: 'water-level',
        component: () => import('@/views/dashboard/water-level/water-level.vue'),
        meta: { title: '水位监控' }
      },
      {
        path: 'points-market',
        name: 'points-market',
        component: () => import('@/views/dashboard/points-market/points-market.vue'),
        meta: { title: '积分商城' }
      }
      // {
      //   path: 'wxgl-smart-detect',
      //   name: 'wxgl-smart-detect',
      //   component: () => import('@/views/dashboard/wxgl-smart-detect/wxgl-smart-detect.vue'),
      //   meta: { title: '造桥机-智能检测' }
      // },
      // {
      //   path: 'wxgl-spray',
      //   name: 'wxgl-spray',
      //   component: () => import('@/views/dashboard/wxgl-spray/cantilever-spray.vue'),
      //   meta: { title: '五新挂篮-喷淋养护' }
      // },
      // {
      //   path: 'wxgl-auto-control',
      //   name: 'wxgl-auto-control',
      //   component: () => import('@/views/dashboard/wxgl-auto-control/wxgl-auto-control.vue'),
      //   meta: { title: '造桥机-自动控制' }
      // },
      // {
      //   path: 'wxgl-auto-control-gl',
      //   name: 'wxgl-auto-control-gl',
      //   component: () => import('@/views/dashboard/wxgl-auto-control-gl/wxgl-auto-control-gl.vue'),
      //   meta: { title: '挂篮-自动控制' }
      // },
      // {
      //   path: 'wxgl-smart-detect-gl',
      //   name: 'wxgl-smart-detect-gl',
      //   component: () => import('@/views/dashboard/wxgl-smart-detect-gl/wxgl-smart-detect-gl.vue'),
      //   meta: { title: '挂篮-智能检测' }
      // }
    ]
  }

  // {
  //   path: '/:pathMatcher(.*)*',
  //   name: 'remaining',
  //   redirect: '/login',
  // },
]
