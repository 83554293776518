<template>
  <div class="main">
    <!-- 顶部的查询框 -->
    <div class="query-panel" v-if="showTopQuery">
      <slot name="top-query"></slot>
      <div v-show="showSwitchBtn"><ViewSwitch v-model="tableView" /></div>
    </div>
    <div class="main-container">
      <!-- 左侧树 -->
      <div class="tree-panel" v-show="_showLeftBar">
        <!-- 左侧树上面 -->
        <div class="left-flex-0">
          <slot name="left-top"></slot>
        </div>
        <!-- 左侧树中间主体 -->
        <div class="left-flex-1">
          <div class="panel">
            <el-scrollbar>
              <slot name="left-center"></slot>
            </el-scrollbar>
          </div>
        </div>
        <!-- 左侧树下面 -->
        <div class="left-flex-3">
          <slot name="left-bottom"></slot>
        </div>
      </div>
      <div class="main-panel">
        <!-- 右侧内容查询框 -->
        <div class="query-panel" v-show="!tableView">
          <slot name="right-query"></slot>
        </div>
        <!-- 右侧内容主体 -->
        <div class="main-panel" v-show="!tableView">
          <div class="panel">
            <slot></slot>
          </div>
        </div>
        <!-- table视图table位置 -->
        <div class="main-panel" v-show="tableView">
          <div class="panel">
            <slot name="table"></slot>
          </div>
        </div>
        <!-- 右侧页码 -->
        <div class="pagination-panel" v-show="!tableView">
          <slot name="right-pagination"></slot>
        </div>
        <!-- table视图页码位置 -->
        <div class="pagination-panel" v-show="tableView">
          <slot name="table-pagination"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import ViewSwitch from '@/components/view-switch/view-switch.vue'
  import { computed } from 'vue'
  const props = withDefaults(
    defineProps<{
      showLeftBar?: boolean
      showSwitchBtn?: boolean
      showTopQuery?: boolean
    }>(),
    {
      showLeftBar: false,
      showSwitchBtn: false,
      showTopQuery: true
    }
  )
  const tableView = defineModel('tableView', { default: false })
  const _showLeftBar = computed(() => {
    if (!tableView.value && props.showLeftBar) {
      return true
    } else if (tableView.value && !props.showLeftBar) {
      return false
    } else if (tableView.value && props.showLeftBar) {
      return false
    } else {
      return props.showLeftBar
    }
  })
</script>
<style scoped lang="less">
  .main {
    height: calc(100% - 40px);
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    flex-direction: column;
    > .query-panel {
      flex: 0 0 auto;
      background-color: white;
      margin-bottom: 10px;
      padding: 0 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      :deep(.el-form) {
        .el-form-item {
          margin: 0.375rem !important;
          > .el-form-item__content > :not(button) {
            min-width: 150px;
          }
        }
      }
      &:empty {
        margin-bottom: 0px;
      }
    }
    > .main-container {
      width: 100%;
      display: flex;
      flex: 1 1 auto;
      position: relative;
      > .tree-panel {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 4px;
        margin-right: 10px;
        padding: 10px;
        width: 400px;
        overflow-x: hidden;
        > .left-flex-0 {
          flex: 0 0 auto;
          width: 100%;
          :deep(> *) {
            margin-bottom: 10px;
          }
        }
        > .left-flex-1 {
          flex: 1 1 auto;
          position: relative;
          > .panel {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }
        }
        > .left-flex-3 {
          flex: 0 0 auto;
          width: 100%;
          :deep(> *) {
            margin-top: 10px;
          }
        }
      }
      > .main-panel {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box;
        > .query-panel {
          flex: 0 0 auto;
          width: 100%;
          :deep(> *) {
            margin-bottom: 10px;
          }
          :deep(.el-form-item) {
            margin-bottom: 0 !important;
          }
        }
        > .main-panel {
          flex: 1 1 auto;
          width: 100%;
          position: relative;
          > .panel {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            :deep(.el-form-item) {
              display: flex;
              margin-top: 0;
              margin-bottom: 18px;
              .el-form-item__content {
                & > div {
                  width: 100%;
                }
              }
            }
          }
        }
        > .pagination-panel {
          flex: 0 0 auto;
          width: 100%;
          :deep(> *) {
            margin-top: 10px;
          }
          :deep(.el-pagination) {
            float: right;
          }
        }
      }
    }
  }
</style>
