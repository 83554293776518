<template>
  <el-drawer :size="size" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false" v-model="visible"
    :title="title" @closed="visible = false" @close="visible = false" :append-to-body="appendToBody" v-bind="$attrs">
    <slot></slot>
    <template #footer v-if="showFooter">
      <div>
        <slot name="footer"></slot>
      </div>
    </template>
  </el-drawer>
</template>
<script setup lang="ts">
const visible = defineModel<boolean>();

const props = withDefaults(
  defineProps<{
    size: string | number;
    title: string;
    showFooter?: boolean;
    appendToBody?: boolean;
  }>(),
  {
    showFooter: true,
    appendToBody: true,
  }
);
</script>
<style scoped lang="less">
</style>
