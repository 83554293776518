import { createApp } from 'vue'
import VueCesium from 'vue-cesium'
import App from '@/App.vue'
import 'vue-cesium/dist/index.css'
import router from './router/index'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import '@/styles/index.less' // global css
import * as Icons from '@element-plus/icons-vue'
import piniaPluginPersist from 'pinia-plugin-persist' // 引入pinia数据持久化插件
import 'default-passive-events' // 添加事件管理者'passive'，来阻止'touchstart'事件，让页面更加流畅。 解决chrome下的warning问题
import VueEcharts from 'vue-echarts'
import 'animate.css/animate.min.css'
import VueTianditu from 'vue-tianditu'
// import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue'
import localforage from 'localforage'

localforage.config({ driver: localforage.INDEXEDDB })
const app = createApp(App)
// 将vue-echarts 注册为全局组件, <VChart option:'xxx' /> 可在任意.vue中使用
app.component('VChart', VueEcharts)
// 动态加载菜单图标
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key as keyof typeof Icons])
})
// 解决报错ERROR ResizeObserver loop limit exceeded
// type DebounceFunction = (...args: any[]) => void;
// const debounce = (fn: DebounceFunction, delay: number): DebounceFunction => {
//   let timer: number | null = null;
//   return function (
//     this: ThisParameterType<DebounceFunction>,
//     ...args: any[]
//   ): void {
//     clearTimeout(timer as number | undefined);
//     timer = window.setTimeout(function (
//       this: ThisParameterType<DebounceFunction>
//     ) {
//       fn.apply(this, args);
//     },
//     delay);
//   };
// };
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(
//     callback: (entries: ResizeObserverEntry[], observer: ResizeObserver) => void
//   ) {
//     const debouncedCallback = debounce(callback, 16);
//     super(debouncedCallback);
//   }
// };

import MainLayout from '@/components/main-layout/main-layout.vue'
import Drawer from '@/components/drawer/drawer.vue'
import ViewSwitch from '@/components/view-switch/view-switch.vue'

export const pinia = createPinia()
pinia
  .use(({ store }) => {
    const initialState = JSON.parse(JSON.stringify(store.$state))
    store.$reset = () => {
      store.$patch(initialState)
    }
  })
  .use(piniaPluginPersist)

app
  .use(pinia)
  .component('MainLayout', MainLayout)
  .component('Drawer', Drawer)
  .component('ViewSwitch', ViewSwitch)
  .use(ElementPlus, {
    locale: zhCn
  })
  .use(router)
  .use(VueCesium, {
    // mars3dConfig: {
    //   include: 'mars3d'
    // },
    cesiumPath: '/cesium/Cesium.js',
    accessToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMzRlMTM1OC1jMWI1LTQyMTktYWNjZi0xZmViZGE3NmU0NDAiLCJpZCI6MTMyNDI2LCJpYXQiOjE2ODA3NzEyMjR9.-x8U_9RW2ZfTY0r41jiXJ7WbIteVMUUaTSNJPBlzkHU'
  })
  .use(VueTianditu, {
    v: '4.0',
    tk: '105379f2fbc692ec6e776a19326b52f7'
  })
  .mount('#app')
