<template>
  <div class="switch">
    <el-tooltip effect="dark" :content="content" placement="bottom-end">
      <el-button link icon="Switch" @click="handleSwitch">切换视图</el-button>
      <!-- <el-icon :size="18" @click="handleSwitch"><Switch /></el-icon> -->
    </el-tooltip>
  </div>
</template>
<script setup lang="ts">
  import { computed } from 'vue'
  const tableView = defineModel({ default: false })
  const content = computed(() => {
    return tableView.value ? '切换为默认视图' : '切换为表格视图'
  })
  const handleSwitch = () => {
    tableView.value = !tableView.value
  }
</script>
<style scoped lang="less">
  .switch {
    float: right;
  }
</style>
